@import url(https://fonts.googleapis.com/css2?family=Cabin:wght@400;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* min-width: 100vh; */
  min-height: 100vh;
  scroll-behavior: smooth;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img {
  width: 100%;
  height: 100%;
}
#about {
    /* height: 500px; */
    height: auto;
    width: 100%;
    display: block;
    margin-bottom: 100px;
    scroll-margin-top: 100px;
    /* flex-direction: column;
    justify-content: center;
    align-items: center; */
    /*background-color: #D6EAF8;*/
}

div.title-about {
    /* margin-top: 25px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5px;
}

div.title-about > h1 {
    font-weight: bolder;
    font-size: 2rem;
    color: #FFFFF0;
}

div.title-about span {
    font-weight: 500;
}

div#profile-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

div.profile {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: 10px;
    align-items: center;
}

div#profile-desc {
    /*max-width: 350px;*/
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-items: center;
}

div.profile-pic{
    width: 250px;
}


div.profile-pic > img {
    box-shadow: 0 0 10px 2px;
    border-radius: 50%;
}

div.info {
    margin-left: 20px;
    margin-right: 20px;
    max-width: 400px;
    width: 100%;
}
div.greetings {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;

}
div.greetings > h1 {
    font-size: 1.5rem;
    color: #fffdf9;
    text-align: left;
}


div.greetings:nth-child(1) {
    font-weight: 400;
    margin-top: 10px;
    font-size: 2rem;
}

div.info > p {
    text-align: justify;
    font-weight: 600;
    color: #F5F5DC;
    padding-bottom: 10px;
    font-size: 1rem;
}

div.education {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    margin-top: 15px;
    margin-right: 30px
}
div#education-bg {
    color: #F5F5DC;
}
div.education-logo {
    width: 75px;
    height: 50px;
}

@media (min-width: 768px){
    div.profile {
        flex-direction: row;
        align-items: flex-start;
    }
    div#profile-desc {
        max-width: 350px;

    }
    div.profile-pic > img {
        border-radius: 50%;
        box-shadow: 15px 15px 10px 0;
    }
    div.info {
        width: 60%;
        margin-right: 0;
        margin-left:35px;

    }
    div.info {
        width: 40%;
    }
    div.info > p {
        font-size: 1.1rem;
    }

    div.greetings {
        flex-direction: column;
        justify-content: initial;
        align-items: flex-start;
        margin-top: 20px;
    }

    div.subsection > ul {
        -webkit-column-count: 2;
                column-count: 2;
    }
    div.domain {
        display: inline-block;
    }
    div.domain span {
        left: 89%;
    }

}



.navbar {
    height: 75px;
    min-width: 100%;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed; 
    top: 0;
    z-index: 1;
    box-shadow: 0px 0px 18px 1px rgb(0 0 0 / 10%);
}
div.name {
    position: fixed;
    left: 50px;
    padding-bottom: 10px;
}
div.name > h1 {
    font-size: 2.5rem;
    color: white;
    cursor: default;
}
div.name > h1:hover {
    font-weight: bolder;
    text-shadow: 1px 1px;
    /* color:dodgerblue !important; */
}

#navbar-menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: fixed;
    right: 60px;
}

#navbar-menu h2 {
    font-size: 1rem;
    margin-top: 15px;
    margin-bottom: 15px;
}
#navbar-menu h2:hover{
    cursor: pointer;
}


.outerText{
    margin-right: 20px;
    width: 47%;
    position: relative;
    color: white;
    display: flex;
    justify-content: center;
}

div.navbar-hr {
    width: 0;
    height: 3px;
    background:white;
    position: absolute;
    bottom:1px;
    transition: width .3s;
    display:flex;
    justify-content: center;
}


h2:hover{
    cursor: default;
}

h2:hover + div.navbar-hr{
    width: inherit !important;
    -webkit-transform: scaleX(1.8) !important;
            transform: scaleX(1.8) !important;
}


div#dropdown-menu {
    display: none;
}

div.bar1, div.bar2, div.bar3 {
    width: 30px;
    height: 3px;
    background-color: white;
    margin: 6px 0;
    transition: 0.4s;
    pointer-events: none;

}

.rotate .bar1 {
    -webkit-transform: rotate(-45deg) translate(-3px, 3px);
    transform: rotate(-45deg) translate(-3px, 3px);
  }
  
.rotate .bar2 {opacity: 0;}

.rotate .bar3 {
    -webkit-transform: rotate(45deg) translate(-9px, -10px);
    transform: rotate(45deg) translate(-9px, -10px);
  }

div.scrollUp-button {
    position: fixed;
    bottom: 25px;
    right: 20px;
    text-align: center;
    width: 35px;
    height: 35px;
    background-color: rgba(22, 41, 54);
    display: none;
    border-radius: 50%;
}
div.scrollUp-button:hover {
    cursor: pointer;
}
div.scrollUp-button > img {
    width: 10px;
    height: auto;
    position: relative;
    top: 7px;
}


div.nav-logo-container {
    position: fixed;
    left: 25px;
    color: #ffffff;

}

div.nav-logo {
    font-size: 1.5rem;
}

div.nav-logo > span {
    padding-left: 5px;
    font-size: 2.5rem;
}

div.overlay {
    width: 100%;
    background-color: #33333380;
    transition: height .5s;
    position: absolute;
    top: 75px;
    display: none;
}
div.dropdown-selection {
    height: 0;
    overflow: hidden;
    transition: height .5s;
    width: 93%;
}
div.dropdown-selection > h2 {
    position: relative;
    left: 20px;
    font-size: 1.1rem;
    font-weight: 300;
    margin-bottom: 5px;
    margin-top: 10px;
    color: #fff;
    margin-left: 10px;
}

div.dropdown-selection > div.dropdown-hr{
    width: 100%;
    height: 1px;
    position: relative;
    left: 20px;
    background-color: white;
}

div.dropdown-selection > h2:hover{
    cursor: pointer;
    font-weight: 600 !important;
}

@media (max-width: 850px) {
    div#dropdown-menu {
        display: initial;
        position: fixed;
        right: 50px;
    }
    div#dropdown-menu:hover{
        cursor: pointer;
    }

    div#navbar-menu {
        display: none;
    }
    div.overlay {
        display: initial;
    }
    div.name > h1 {
        color: black !important;
    }
    div#dropdown-menu > div {
        background: #fff;
    }
    div.nav-logo-container {
        left: 20px;
    }
    
}

@media (max-width: 500px){
    div.name, div.dropdown-selection > h2 {
        left: 20px;
    }
    div#dropdown-menu {
        right: 20px;
    }
    
    
}
section#home {
    /* min-width: 1000px; */
    height: 100vh;
    /* margin-top: 75px; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}


div.background-img {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0; 
    left: 0;
    right: 0;
    bottom: 0;
    /* margin-top: 75px;     */
}    

div.background-img > img {
    width: 100%;
    height: 100%;
    -webkit-filter: brightness(50%);
            filter: brightness(50%);
    object-fit: cover;
    z-index: -1;

    
}

div.intro{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
div.intro > h2 {
    margin: 0;
}
div.intro > h2 {
  font-size: 2.5rem;
}
div.intro  * {
    color: white;
}
div.intro > h1 {
  font-size: 4rem;
  font-weight: bolder;
  margin-top: 20px;
  margin-bottom: 30px;
  text-align: center;
}

.text1 {
  margin-left: 10px;
}

span.text:before {
    content: "Developer.";
    -webkit-animation: fade 15s infinite;
            animation: fade 15s infinite;
    -o-animation: fade 15s infinite;
    -moz-animation: fade 15s infinite;
}

span.text:before {
    content: "Developer.";
    -webkit-animation: fade 15s infinite;
            animation: fade 15s infinite;
    -o-animation: fade 15s infinite;
    -moz-animation: fade 15s infinite;
}

@-webkit-keyframes fade {
    0% {
        opacity: 0;
    }

    8.33% {
        opacity: 1;
        content: "Developer.";
    }

    18.33% {
        opacity: 1;
        content: "Developer.";
    }

    26.67% {
        opacity: 0;
        content: "Developer.";
    }

    28.33% {
        opacity: 0;
        content: "Test Engineer.";
    }

    38.33% {
        opacity: 1;
        content: "Test Engineer.";
    }

    48.33% {
        opacity: 1;
        content: "Test Engineer.";
    }

    56.67% {
        opacity: 0;
        content: "Test Engineer.";
    }

    58.33% {
        opacity: 0;
        content: "Quality Engineer.";
    }

    68.33% {
        opacity: 1;
        content: "Quality Engineer.";
    }

    78.33% {
        opacity: 1;
        content: "Quality Engineer.";
    }

    100% {
        opacity: 0;
        content: "Quality Engineer.";
    }
}

@keyframes fade {
    0% {
        opacity: 0;
    }

    8.33% {
        opacity: 1;
        content: "Developer.";
    }

    18.33% {
        opacity: 1;
        content: "Developer.";
    }

    26.67% {
        opacity: 0;
        content: "Developer.";
    }

    28.33% {
        opacity: 0;
        content: "Test Engineer.";
    }

    38.33% {
        opacity: 1;
        content: "Test Engineer.";
    }

    48.33% {
        opacity: 1;
        content: "Test Engineer.";
    }

    56.67% {
        opacity: 0;
        content: "Test Engineer.";
    }

    58.33% {
        opacity: 0;
        content: "Quality Engineer.";
    }

    68.33% {
        opacity: 1;
        content: "Quality Engineer.";
    }

    78.33% {
        opacity: 1;
        content: "Quality Engineer.";
    }

    100% {
        opacity: 0;
        content: "Quality Engineer.";
    }
}



div.socialnetwork {
    display: flex;
    position: relative;
    margin-bottom: 40px;
  }


  div.github, div.linkedin, div.gmail {
    width: 20px;
    height: 20px;
    cursor: pointer;
    border-radius: 30px;
    padding: 10px;
    border: solid #FFFFF0;
    margin-right: 30px;
    overflow: hidden;
    text-align: center;
  }

  div.github >img, div.linkedin > img, div.gmail * {
    width: 100%;
    height: 100%;
  }
  div.gmail {
    margin-right: 0 !important;
  }

div.github:hover > img, div.linkedin:hover > img, div.gmail:hover img {
    -webkit-animation: forward360 0.5s linear;
            animation: forward360 0.5s linear;
}
div.github:hover > img, div.linkedin:hover > img, div.gmail:hover img{
    -webkit-animation: reverse360 0.5s linear;
            animation: reverse360 0.5s linear;
}

@-webkit-keyframes forward360 {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform:  rotate(359deg);
                transform:  rotate(359deg);
    }
}

@keyframes forward360 {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform:  rotate(359deg);
                transform:  rotate(359deg);
    }
}

@-webkit-keyframes reverse360 {
    from {
        -webkit-transform:  rotate(360deg);
                transform:  rotate(360deg);
    }
    to {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

}

@keyframes reverse360 {
    from {
        -webkit-transform:  rotate(360deg);
                transform:  rotate(360deg);
    }
    to {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

}


div.resume{
  padding: 15px 20px 15px 20px;
  border-radius: 25px;
  border-color: white;
  border-style: solid;
  cursor: default;
  color: white;
  background-color: transparent;
  transition: 1s;
  z-index: 1;
}


div.resume:hover {
    background-color: white;
    color:rgba(22, 41, 54);
    cursor: pointer;
    
}


div.move-down {
    position: absolute;
    bottom: 0;
    padding-top: 10px;
    padding-bottom: 5px;
    border-radius: 20px;
    border-color: white;
    border-style: solid;
    background-color: transparent;
    -webkit-animation: bounce 2s infinite;
            animation: bounce 2s infinite;
    cursor: default;
    width: 25px;
    height: 30px;
    margin-bottom: 20px;
    text-align: center;
    transition: 0.3s ease-in;
}

div.move-down > img {
    width: 50%;
    height: 50%;
    position: relative;
    top: 10px;
}

div.move-down:hover {
    background: whitesmoke;
    cursor: pointer;
}

div.move-down img:nth-child(2){
    display: none;
    visibility: hidden;
}

div.move-down:hover img:nth-child(2){
    display: block;
    visibility: visible;
    align-items: center;
    left: 6px;
}

div.move-down:hover img:nth-child(1) {
    display: none;
    visibility: hidden;
}





@-webkit-keyframes bounce {
  0%, 30%,60%,100% {bottom: 0px;}
  5% {bottom: 25px} 
  20% {bottom: 26px;}
  45% {bottom: 20px;}
  75% {bottom: 10px;}
}





@keyframes bounce {
  0%, 30%,60%,100% {bottom: 0px;}
  5% {bottom: 25px} 
  20% {bottom: 26px;}
  45% {bottom: 20px;}
  75% {bottom: 10px;}
}

div.display-resume {
  position: absolute;
}

@media (max-width: 850px){
  /* section#home {
    min-height: 85vh;
    background-color: black;
  } */
  /* div.background-img {
    height: 85%;
  } */
  div.intro > h2{
    font-size: 1.8rem;

  }
  div.intro  > h1 {
    font-size: 3rem;
  }


}


@media (max-width: 525px){
  div.who > h1 {
    position: relative;
    margin-top: 20px;
  }
}

@media (max-width: 850px){
  section#home, div.background-img {
    height: 80vh;
    }

}
#experience {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /*background-color: #F5F5F5;*/
    scroll-margin-top: 100px;
}

div.title-experience{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    margin-top: 25px;
}

div.title-experience > p {
    font-size: 2rem;
    text-align: center;
    color: #FFFFF0;
}


div.work-history {
    display: flex;
    flex-direction: row;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
}

div.vertical-bar {
    width: 1px;
    margin-right: 20px;
    background-color: black;
    margin-top: -30px;
    position: relative;
}

div.experience {
    border: solid 1px;
    padding-right: 25px;
    margin-bottom: 30px;
    padding-bottom: 20px;
    max-width: 500px;
    display: flex;
    flex-direction: row;
    position: relative;
}

div.briefcase{
    width: 25px;
    height: 25px;
    position: absolute;
    left: -34px;
    margin-top:10px;

}
div.job-desc > div.job-desc-divider {
    width: 50px;
    height: 3px;
    background-color: #FFFFF0;
    margin: 8px 0 8px 5px;
}

div.job-desc > p {
    margin-left: 5px;
    font-size: .9rem;
}
div.job-desc > h3:first-child {
    padding: 13px 0 5px 0;
}
div.job-desc > h3 {
    color: rgba(22, 41, 50);
    padding: 5px 0 5px 0;
    margin-left:5px;
}

div.job-desc > p {
    color: #F5F5DC;
}










#project {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    scroll-margin-top: 100px;

}

div.project-row {
    margin: 25px;
}
div.title-projects {
    display: flex;
    flex-direction: column;
    align-items: center;
}

div.title-projects > h1 {
    font-size: 2rem;
    font-weight: 400;
    color: #FFFFF0
}
div.title-projects  span {
    font-weight: 600;
}

div.project-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

div.project-img-container {
    max-width: 350px;
    margin-top: 20px;
    margin-bottom: 20px;

}
div.project-img-container > img {
    box-shadow: 0 0 3px 0;
    background-color: whitesmoke;
}

div.create-with {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
}
div.create-with > p {
    margin:0;
    max-width: 450px;
    text-align: justify;
    color: #F5F5DC
}

div.logos-container {
    display: flex;
    align-items: center;
    width: 100%;
}

div.logos-container > img {
    height: 25px;
    width: 25px;
    padding-left: 10px;
    /* media: 35px */
}
div.logos-container > h4 {
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 1.25rem;
    color:#fffdf9;
}
div.logos-container > span {
    margin-left: 10px;
    font-size: 1.1rem;
    font-weight: bold;
}


img.adjust {
    height: 35px;
    width: 35px;
}

div.project-description {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
div.project-description > h3 {
    margin-bottom: 0;
    margin-top: 0;
    font-size: 1.5rem;
    color: rgba(22, 41, 50);
}

div.project-links {
    display: flex;
    flex-direction: row;
}

div.project-links > div {
    margin-top: 20px;
    padding: 10px 15px 10px 15px;
    margin-right: 10px;
    border-radius: 25px;
    border-color: transparent;
    border-style: solid;
    cursor: default;
    color: #FFFFF0;
    background-color: rgba(22, 41, 54);
    transition: 1s;
    text-align: center;;
}
div.project-links > div:hover {
    border-color: #FFFFF0;
    background-color: transparent;
    color: rgba(22, 41, 54);
    cursor: pointer;
}




@media (max-width: 400px){
    div.logos-container > img{
        padding-left: 8px;
    }
}


@media (min-width: 400px){

    div.create-with >p {
        margin-top: 0;
    }
}

@media (min-width: 850px){
    div.project-content {
        flex-direction: row;
    }
    div.project-description {
        margin-left:25px;
        max-width: 500px;
        align-items: flex-start;
    }
    div.create-with {
        align-items: unset;
    }
    
}
#contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    /* background:linear-gradient(180deg,#d4c9ad 15%, #eae5d9); */
    /*background-color: #f5f5f5;*/
    scroll-margin-top: 50px;

}

div.title-contact {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 25px;
}
div.title-contact > h1 {
    font-weight: 500;
    font-size: 2rem;
    color: #FFFFF0;
}

div.title-contact  span {
    font-weight: 600;
}

#contact-form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.userInfo > input, textarea {
    padding-left: 5px;
}

div.contact-content {
    width: 40%;
    margin-left: 50px;
    margin-right: 50px;
}
div.contact-content > p {
    font-size: 1.2rem;
    color: #F5F5DC;
    margin-bottom: 20px;
}

div.field, div.field > input, div.field > textarea {
    width: 100%;
    padding: 10px;
}

div.submit-button {
    margin: 25px;
    border: solid;
    padding: 10px;
    background-color: black;
    border-radius: 5px;
    transition: 0.5s;

}

div.submit-button:hover {
    background-color: white;
}
div.submit-button > input {
    color: white;
    border: none;
    background-color: black;
    font-size: 1rem;
    transition: 0.5s;

}
div.submit-button:hover input {
    background-color: white;
    color: black;
}
.userInfo > h3 {
    margin-bottom: 5px;
    color: rgba(22, 41, 54)
}

.userInfo > input {
    width: 100%;
    height: 35px;
    border-top: solid;
    border-left: solid;
    font-size: 1.2rem;
    margin-bottom: 10px;
}
div.contact-content span {
    font-weight: 600;
    color: rgba(22, 41, 54);
}


.userInfo > textarea {
    width: 100%;
    margin-bottom: 20px;
    font-size: 1.2rem;
    border-top: solid;
    border-left: solid;
}
div.submit-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

div.submit-container > button {
    padding: 10px 20px;
    background-color: rgba(22, 41, 54);
    color: white;
    border: solid;
    border-color: rgba(22, 41, 54, 0.6);
    border-radius: 5px;
    transition: 0.5s;
    margin-bottom: 20px;
    font-size: 1rem;
}

div.submit-container > button:hover {
    background-color: #fffdf9;
    color: rgba(22, 41, 54);
    cursor: pointer;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    appearance: none;
    margin: 0; 
}

/* div.field > * {
    width: 500px;
}
div.field > input {
    height: 25px;
} */
footer {
    display: flex;
    align-items: center;
    /*height: 150px;*/
    /*background-color: #fffdf9;*/
    justify-content: center;
    padding-bottom: 10px;
}

div.footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

div.footer-content > div {
    margin-bottom: 0;
}
div.footer-content h3 {
    margin-top: 20px;
    margin-bottom: 0;
    text-align: center;
    color: rgb(255, 255, 255, 0.7);
}

#network-footer > div {
    border-radius: 30px;
    padding: 10px;
    border: solid #000;
    margin-right: 30px;
    overflow: hidden;
    text-align: center;
}

div#rotate-logo1:hover > img, div#rotate-logo2:hover > img, div#rotate-logo3:hover img {
    -webkit-animation: forward360 0.5s linear;
            animation: forward360 0.5s linear;
}
div#rotate-logo1 > img, div#rotate-logo2 > img, div#rotate-logo3 img {
    -webkit-animation: reverse360 0.5s linear;
            animation: reverse360 0.5s linear;
}

@-webkit-keyframes forward360 {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform:  rotate(359deg);
                transform:  rotate(359deg);
    }
}

@keyframes forward360 {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform:  rotate(359deg);
                transform:  rotate(359deg);
    }
}

@-webkit-keyframes reverse360 {
    from {
        -webkit-transform:  rotate(360deg);
                transform:  rotate(360deg);
    }
    to {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    
}

@keyframes reverse360 {
    from {
        -webkit-transform:  rotate(360deg);
                transform:  rotate(360deg);
    }
    to {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    
}

@media (max-width: 600px){
    div.contact-content {
        width: 60%;
    }

}
* {
    font-family: 'Cabin', sans-serif, serif;
}
input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}


.App > section {
    position: relative;
}

div#padding{
    height: 100px;
    width: 100%;

    /*background: linear-gradient(to bottom, #000000, #394249)*/
    background: linear-gradient(to bottom, #000000 0%, #394249 75%, #394249 100%);

}
div#transition{
    /*background: linear-gradient(to bottom, rgba(57, 66, 73, .9) 0%, #B6AFA8 5%, #C0C0C0 100%)*/
    /*background: linear-gradient(to bottom, rgba(57, 66, 73, 1) 0%, #D8BFD8 100%);*/
    /*background: linear-gradient(to bottom, rgba(57, 66, 73, 1) 0%, rgba(57, 66, 73, 0.8) 2% ,#AAB5AA 10%);*/
    background: linear-gradient(to bottom, rgba(57, 66, 73, 1) 0%, rgba(57, 66, 73, 0.9) 1% , #697073 90%, #162936 100%)



}

#about::before,#education::before, #project::before, #contact::before {
    content: '';
    display: block;
    height:80px;
    visibility: hidden;
    background: black;
}

* {
    margin: 0;
    padding: 0;
}

div.divider {
    margin: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

div.divider > span {
    font-size: 3rem;
    font-weight: bold;
    color: #FFFFF0;
}

div.divider .hr-divider {
    width: 30px;
    height: 1px;
    background-color: #FFFFF0;
    margin-bottom: 20px;
}
