#project {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    scroll-margin-top: 100px;

}

div.project-row {
    margin: 25px;
}
div.title-projects {
    display: flex;
    flex-direction: column;
    align-items: center;
}

div.title-projects > h1 {
    font-size: 2rem;
    font-weight: 400;
    color: #FFFFF0
}
div.title-projects  span {
    font-weight: 600;
}

div.project-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

div.project-img-container {
    max-width: 350px;
    margin-top: 20px;
    margin-bottom: 20px;

}
div.project-img-container > img {
    box-shadow: 0 0 3px 0;
    background-color: whitesmoke;
}

div.create-with {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
}
div.create-with > p {
    margin:0;
    max-width: 450px;
    text-align: justify;
    color: #F5F5DC
}

div.logos-container {
    display: flex;
    align-items: center;
    width: 100%;
}

div.logos-container > img {
    height: 25px;
    width: 25px;
    padding-left: 10px;
    /* media: 35px */
}
div.logos-container > h4 {
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 1.25rem;
    color:#fffdf9;
}
div.logos-container > span {
    margin-left: 10px;
    font-size: 1.1rem;
    font-weight: bold;
}


img.adjust {
    height: 35px;
    width: 35px;
}

div.project-description {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
div.project-description > h3 {
    margin-bottom: 0;
    margin-top: 0;
    font-size: 1.5rem;
    color: rgba(22, 41, 50);
}

div.project-links {
    display: flex;
    flex-direction: row;
}

div.project-links > div {
    margin-top: 20px;
    padding: 10px 15px 10px 15px;
    margin-right: 10px;
    border-radius: 25px;
    border-color: transparent;
    border-style: solid;
    cursor: default;
    color: #FFFFF0;
    background-color: rgba(22, 41, 54);
    transition: 1s;
    text-align: center;;
}
div.project-links > div:hover {
    border-color: #FFFFF0;
    background-color: transparent;
    color: rgba(22, 41, 54);
    cursor: pointer;
}




@media (max-width: 400px){
    div.logos-container > img{
        padding-left: 8px;
    }
}


@media (min-width: 400px){

    div.create-with >p {
        margin-top: 0;
    }
}

@media (min-width: 850px){
    div.project-content {
        flex-direction: row;
    }
    div.project-description {
        margin-left:25px;
        max-width: 500px;
        align-items: flex-start;
    }
    div.create-with {
        align-items: unset;
    }
    
}