footer {
    display: flex;
    align-items: center;
    /*height: 150px;*/
    /*background-color: #fffdf9;*/
    justify-content: center;
    padding-bottom: 10px;
}

div.footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

div.footer-content > div {
    margin-bottom: 0;
}
div.footer-content h3 {
    margin-top: 20px;
    margin-bottom: 0;
    text-align: center;
    color: rgb(255, 255, 255, 0.7);
}

#network-footer > div {
    border-radius: 30px;
    padding: 10px;
    border: solid #000;
    margin-right: 30px;
    overflow: hidden;
    text-align: center;
}

div#rotate-logo1:hover > img, div#rotate-logo2:hover > img, div#rotate-logo3:hover img {
    animation: forward360 0.5s linear;
}
div#rotate-logo1 > img, div#rotate-logo2 > img, div#rotate-logo3 img {
    animation: reverse360 0.5s linear;
}

@keyframes forward360 {
    from {
        transform: rotate(0deg);
    }
    to {
        transform:  rotate(359deg);
    }
}

@keyframes reverse360 {
    from {
        transform:  rotate(360deg);
    }
    to {
        transform: rotate(0deg);
    }
    
}

@media (max-width: 600px){
    div.contact-content {
        width: 60%;
    }

}