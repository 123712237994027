#experience {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /*background-color: #F5F5F5;*/
    scroll-margin-top: 100px;
}

div.title-experience{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    margin-top: 25px;
}

div.title-experience > p {
    font-size: 2rem;
    text-align: center;
    color: #FFFFF0;
}


div.work-history {
    display: flex;
    flex-direction: row;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
}

div.vertical-bar {
    width: 1px;
    margin-right: 20px;
    background-color: black;
    margin-top: -30px;
    position: relative;
}

div.experience {
    border: solid 1px;
    padding-right: 25px;
    margin-bottom: 30px;
    padding-bottom: 20px;
    max-width: 500px;
    display: flex;
    flex-direction: row;
    position: relative;
}

div.briefcase{
    width: 25px;
    height: 25px;
    position: absolute;
    left: -34px;
    margin-top:10px;

}
div.job-desc > div.job-desc-divider {
    width: 50px;
    height: 3px;
    background-color: #FFFFF0;
    margin: 8px 0 8px 5px;
}

div.job-desc > p {
    margin-left: 5px;
    font-size: .9rem;
}
div.job-desc > h3:first-child {
    padding: 13px 0 5px 0;
}
div.job-desc > h3 {
    color: rgba(22, 41, 50);
    padding: 5px 0 5px 0;
    margin-left:5px;
}

div.job-desc > p {
    color: #F5F5DC;
}









