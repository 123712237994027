section#home {
    /* min-width: 1000px; */
    height: 100vh;
    /* margin-top: 75px; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}


div.background-img {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0; 
    left: 0;
    right: 0;
    bottom: 0;
    /* margin-top: 75px;     */
}    

div.background-img > img {
    width: 100%;
    height: 100%;
    filter: brightness(50%);
    object-fit: cover;
    z-index: -1;

    
}

div.intro{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
div.intro > h2 {
    margin: 0;
}
div.intro > h2 {
  font-size: 2.5rem;
}
div.intro  * {
    color: white;
}
div.intro > h1 {
  font-size: 4rem;
  font-weight: bolder;
  margin-top: 20px;
  margin-bottom: 30px;
  text-align: center;
}

.text1 {
  margin-left: 10px;
}

span.text:before {
    content: "Developer.";
    animation: fade 15s infinite;
    -o-animation: fade 15s infinite;
    -moz-animation: fade 15s infinite;
}

span.text:before {
    content: "Developer.";
    animation: fade 15s infinite;
    -o-animation: fade 15s infinite;
    -moz-animation: fade 15s infinite;
}

@keyframes fade {
    0% {
        opacity: 0;
    }

    8.33% {
        opacity: 1;
        content: "Developer.";
    }

    18.33% {
        opacity: 1;
        content: "Developer.";
    }

    26.67% {
        opacity: 0;
        content: "Developer.";
    }

    28.33% {
        opacity: 0;
        content: "Test Engineer.";
    }

    38.33% {
        opacity: 1;
        content: "Test Engineer.";
    }

    48.33% {
        opacity: 1;
        content: "Test Engineer.";
    }

    56.67% {
        opacity: 0;
        content: "Test Engineer.";
    }

    58.33% {
        opacity: 0;
        content: "Quality Engineer.";
    }

    68.33% {
        opacity: 1;
        content: "Quality Engineer.";
    }

    78.33% {
        opacity: 1;
        content: "Quality Engineer.";
    }

    100% {
        opacity: 0;
        content: "Quality Engineer.";
    }
}



div.socialnetwork {
    display: flex;
    position: relative;
    margin-bottom: 40px;
  }


  div.github, div.linkedin, div.gmail {
    width: 20px;
    height: 20px;
    cursor: pointer;
    border-radius: 30px;
    padding: 10px;
    border: solid #FFFFF0;
    margin-right: 30px;
    overflow: hidden;
    text-align: center;
  }

  div.github >img, div.linkedin > img, div.gmail * {
    width: 100%;
    height: 100%;
  }
  div.gmail {
    margin-right: 0 !important;
  }

div.github:hover > img, div.linkedin:hover > img, div.gmail:hover img {
    animation: forward360 0.5s linear;
}
div.github:hover > img, div.linkedin:hover > img, div.gmail:hover img{
    animation: reverse360 0.5s linear;
}

@keyframes forward360 {
    from {
        transform: rotate(0deg);
    }
    to {
        transform:  rotate(359deg);
    }
}

@keyframes reverse360 {
    from {
        transform:  rotate(360deg);
    }
    to {
        transform: rotate(0deg);
    }

}


div.resume{
  padding: 15px 20px 15px 20px;
  border-radius: 25px;
  border-color: white;
  border-style: solid;
  cursor: default;
  color: white;
  background-color: transparent;
  transition: 1s;
  z-index: 1;
}


div.resume:hover {
    background-color: white;
    color:rgba(22, 41, 54);
    cursor: pointer;
    
}


div.move-down {
    position: absolute;
    bottom: 0;
    padding-top: 10px;
    padding-bottom: 5px;
    border-radius: 20px;
    border-color: white;
    border-style: solid;
    background-color: transparent;
    animation: bounce 2s infinite;
    cursor: default;
    width: 25px;
    height: 30px;
    margin-bottom: 20px;
    text-align: center;
    transition: 0.3s ease-in;
}

div.move-down > img {
    width: 50%;
    height: 50%;
    position: relative;
    top: 10px;
}

div.move-down:hover {
    background: whitesmoke;
    cursor: pointer;
}

div.move-down img:nth-child(2){
    display: none;
    visibility: hidden;
}

div.move-down:hover img:nth-child(2){
    display: block;
    visibility: visible;
    align-items: center;
    left: 6px;
}

div.move-down:hover img:nth-child(1) {
    display: none;
    visibility: hidden;
}





@keyframes bounce {
  0%, 30%,60%,100% {bottom: 0px;}
  5% {bottom: 25px} 
  20% {bottom: 26px;}
  45% {bottom: 20px;}
  75% {bottom: 10px;}
}

div.display-resume {
  position: absolute;
}

@media (max-width: 850px){
  /* section#home {
    min-height: 85vh;
    background-color: black;
  } */
  /* div.background-img {
    height: 85%;
  } */
  div.intro > h2{
    font-size: 1.8rem;

  }
  div.intro  > h1 {
    font-size: 3rem;
  }


}


@media (max-width: 525px){
  div.who > h1 {
    position: relative;
    margin-top: 20px;
  }
}

@media (max-width: 850px){
  section#home, div.background-img {
    height: 80vh;
    }

}