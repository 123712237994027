#contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    /* background:linear-gradient(180deg,#d4c9ad 15%, #eae5d9); */
    /*background-color: #f5f5f5;*/
    scroll-margin-top: 50px;

}

div.title-contact {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 25px;
}
div.title-contact > h1 {
    font-weight: 500;
    font-size: 2rem;
    color: #FFFFF0;
}

div.title-contact  span {
    font-weight: 600;
}

#contact-form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.userInfo > input, textarea {
    padding-left: 5px;
}

div.contact-content {
    width: 40%;
    margin-left: 50px;
    margin-right: 50px;
}
div.contact-content > p {
    font-size: 1.2rem;
    color: #F5F5DC;
    margin-bottom: 20px;
}

div.field, div.field > input, div.field > textarea {
    width: 100%;
    padding: 10px;
}

div.submit-button {
    margin: 25px;
    border: solid;
    padding: 10px;
    background-color: black;
    border-radius: 5px;
    transition: 0.5s;

}

div.submit-button:hover {
    background-color: white;
}
div.submit-button > input {
    color: white;
    border: none;
    background-color: black;
    font-size: 1rem;
    transition: 0.5s;

}
div.submit-button:hover input {
    background-color: white;
    color: black;
}
.userInfo > h3 {
    margin-bottom: 5px;
    color: rgba(22, 41, 54)
}

.userInfo > input {
    width: 100%;
    height: 35px;
    border-top: solid;
    border-left: solid;
    font-size: 1.2rem;
    margin-bottom: 10px;
}
div.contact-content span {
    font-weight: 600;
    color: rgba(22, 41, 54);
}


.userInfo > textarea {
    width: 100%;
    margin-bottom: 20px;
    font-size: 1.2rem;
    border-top: solid;
    border-left: solid;
}
div.submit-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

div.submit-container > button {
    padding: 10px 20px;
    background-color: rgba(22, 41, 54);
    color: white;
    border: solid;
    border-color: rgba(22, 41, 54, 0.6);
    border-radius: 5px;
    transition: 0.5s;
    margin-bottom: 20px;
    font-size: 1rem;
}

div.submit-container > button:hover {
    background-color: #fffdf9;
    color: rgba(22, 41, 54);
    cursor: pointer;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

/* div.field > * {
    width: 500px;
}
div.field > input {
    height: 25px;
} */