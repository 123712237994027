#about {
    /* height: 500px; */
    height: auto;
    width: 100%;
    display: block;
    margin-bottom: 100px;
    scroll-margin-top: 100px;
    /* flex-direction: column;
    justify-content: center;
    align-items: center; */
    /*background-color: #D6EAF8;*/
}

div.title-about {
    /* margin-top: 25px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5px;
}

div.title-about > h1 {
    font-weight: bolder;
    font-size: 2rem;
    color: #FFFFF0;
}

div.title-about span {
    font-weight: 500;
}

div#profile-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

div.profile {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: 10px;
    align-items: center;
}

div#profile-desc {
    /*max-width: 350px;*/
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-items: center;
}

div.profile-pic{
    width: 250px;
}


div.profile-pic > img {
    box-shadow: 0 0 10px 2px;
    border-radius: 50%;
}

div.info {
    margin-left: 20px;
    margin-right: 20px;
    max-width: 400px;
    width: 100%;
}
div.greetings {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;

}
div.greetings > h1 {
    font-size: 1.5rem;
    color: #fffdf9;
    text-align: left;
}


div.greetings:nth-child(1) {
    font-weight: 400;
    margin-top: 10px;
    font-size: 2rem;
}

div.info > p {
    text-align: justify;
    font-weight: 600;
    color: #F5F5DC;
    padding-bottom: 10px;
    font-size: 1rem;
}

div.education {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    margin-top: 15px;
    margin-right: 30px
}
div#education-bg {
    color: #F5F5DC;
}
div.education-logo {
    width: 75px;
    height: 50px;
}

@media (min-width: 768px){
    div.profile {
        flex-direction: row;
        align-items: flex-start;
    }
    div#profile-desc {
        max-width: 350px;

    }
    div.profile-pic > img {
        border-radius: 50%;
        box-shadow: 15px 15px 10px 0;
    }
    div.info {
        width: 60%;
        margin-right: 0;
        margin-left:35px;

    }
    div.info {
        width: 40%;
    }
    div.info > p {
        font-size: 1.1rem;
    }

    div.greetings {
        flex-direction: column;
        justify-content: initial;
        align-items: flex-start;
        margin-top: 20px;
    }

    div.subsection > ul {
        column-count: 2;
    }
    div.domain {
        display: inline-block;
    }
    div.domain span {
        left: 89%;
    }

}


