.navbar {
    height: 75px;
    min-width: 100%;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed; 
    top: 0;
    z-index: 1;
    box-shadow: 0px 0px 18px 1px rgb(0 0 0 / 10%);
}
div.name {
    position: fixed;
    left: 50px;
    padding-bottom: 10px;
}
div.name > h1 {
    font-size: 2.5rem;
    color: white;
    cursor: default;
}
div.name > h1:hover {
    font-weight: bolder;
    text-shadow: 1px 1px;
    /* color:dodgerblue !important; */
}

#navbar-menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: fixed;
    right: 60px;
}

#navbar-menu h2 {
    font-size: 1rem;
    margin-top: 15px;
    margin-bottom: 15px;
}
#navbar-menu h2:hover{
    cursor: pointer;
}


.outerText{
    margin-right: 20px;
    width: 47%;
    position: relative;
    color: white;
    display: flex;
    justify-content: center;
}

div.navbar-hr {
    width: 0;
    height: 3px;
    background:white;
    position: absolute;
    bottom:1px;
    transition: width .3s;
    display:flex;
    justify-content: center;
}


h2:hover{
    cursor: default;
}

h2:hover + div.navbar-hr{
    width: inherit !important;
    transform: scaleX(1.8) !important;
}


div#dropdown-menu {
    display: none;
}

div.bar1, div.bar2, div.bar3 {
    width: 30px;
    height: 3px;
    background-color: white;
    margin: 6px 0;
    transition: 0.4s;
    pointer-events: none;

}

.rotate .bar1 {
    -webkit-transform: rotate(-45deg) translate(-3px, 3px);
    transform: rotate(-45deg) translate(-3px, 3px);
  }
  
.rotate .bar2 {opacity: 0;}

.rotate .bar3 {
    -webkit-transform: rotate(45deg) translate(-9px, -10px);
    transform: rotate(45deg) translate(-9px, -10px);
  }

div.scrollUp-button {
    position: fixed;
    bottom: 25px;
    right: 20px;
    text-align: center;
    width: 35px;
    height: 35px;
    background-color: rgba(22, 41, 54);
    display: none;
    border-radius: 50%;
}
div.scrollUp-button:hover {
    cursor: pointer;
}
div.scrollUp-button > img {
    width: 10px;
    height: auto;
    position: relative;
    top: 7px;
}


div.nav-logo-container {
    position: fixed;
    left: 25px;
    color: #ffffff;

}

div.nav-logo {
    font-size: 1.5rem;
}

div.nav-logo > span {
    padding-left: 5px;
    font-size: 2.5rem;
}

div.overlay {
    width: 100%;
    background-color: #33333380;
    transition: height .5s;
    position: absolute;
    top: 75px;
    display: none;
}
div.dropdown-selection {
    height: 0;
    overflow: hidden;
    transition: height .5s;
    width: 93%;
}
div.dropdown-selection > h2 {
    position: relative;
    left: 20px;
    font-size: 1.1rem;
    font-weight: 300;
    margin-bottom: 5px;
    margin-top: 10px;
    color: #fff;
    margin-left: 10px;
}

div.dropdown-selection > div.dropdown-hr{
    width: 100%;
    height: 1px;
    position: relative;
    left: 20px;
    background-color: white;
}

div.dropdown-selection > h2:hover{
    cursor: pointer;
    font-weight: 600 !important;
}

@media (max-width: 850px) {
    div#dropdown-menu {
        display: initial;
        position: fixed;
        right: 50px;
    }
    div#dropdown-menu:hover{
        cursor: pointer;
    }

    div#navbar-menu {
        display: none;
    }
    div.overlay {
        display: initial;
    }
    div.name > h1 {
        color: black !important;
    }
    div#dropdown-menu > div {
        background: #fff;
    }
    div.nav-logo-container {
        left: 20px;
    }
    
}

@media (max-width: 500px){
    div.name, div.dropdown-selection > h2 {
        left: 20px;
    }
    div#dropdown-menu {
        right: 20px;
    }
    
    
}